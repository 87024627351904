import {Activity, Importance, ApiInfo, LoginResponse, Newsletter, Church, Direction, WordAnnotation, AnnotationType, DynamicContent, TagType, User, GetAllResponse, ResponseBase, StructDate, DeployData, PageRoute} from './Models';
import axios from 'axios';
import { GetDataReponse } from './Requests';
import Cache from './AppCache';
import { threadId } from 'worker_threads';

export default class Api {
    public static info: ApiInfo = new ApiInfo();
    static get token(): string {
        return this.info.token;
    }

    public static playSound(urlFromSubDirectory: string) {
        let music = new Audio(this.getSubDirectory(urlFromSubDirectory));
        music.play();
    }

    public static getSubDirectory(filename: string) : string {
        let domain = document.domain;
        let isLocal = domain.match(/localhost/);
        
		return (isLocal ? 'http://localhost:3000/' : 'https://' + document.domain + '/') + filename;
	}

    public static initializeCount: number = 0;

    static wordMethod: (word: string) => void;

    static prefectures: string[] = ['北海道', '青森', '岩手', '宮城', '秋田', '山形', '福島', '茨城', '栃木', '群馬', '埼玉', '千葉', '東京', '神奈川', '新潟', '富山', '石川', '福井', '山梨', '長野', '岐阜', '静岡', '愛知', '三重', '滋賀', '京都', '大阪', '兵庫', '奈良', '和歌山', '鳥取', '島根', '岡山', '広島', '山口', '徳島', '香川', '愛媛', '高知', '福岡', '佐賀', '長崎', '熊本', '大分', '宮崎', '鹿児島', '沖縄', '大韓民国', 'アメリカ'];
    static _users: User[] = [];

    public static ScrollToTop() {
        window.scrollTo(0, 0);
    }

    public static GetUsers(isForce: boolean, func: (users: User[]) => void) {
        if(this._users.length > 0 && !isForce)
            return func(this._users);
        else
            this.Post<GetAllResponse<User>>('GetAllUsers', {token: this.token}, data => {
                if(data.isSucceed) {
                    this._users = JSON.parse(data.json);
                    func(this._users);
                }
             });
    }

    public static GetWeek(date: Date): string {
        return [ "日", "月", "火", "水", "木", "金", "土" ][date.getDay()];
    }

    public static getAnnotationIcon(annotation: WordAnnotation | null | undefined): string {
        if(annotation == undefined || annotation == null)
            return '';

        switch(annotation.type) {
            case AnnotationType.Person:
                return 'fas fa-user';
            case AnnotationType.Word:
                return 'fas fa-book';
            case AnnotationType.Spot:
                return 'fas fa-map-marker-alt';
            default:
                return '';
        }
    }

    

    public static words: WordAnnotation[] =[];

    public static GetAnnotation(word: string): WordAnnotation | undefined {
        let typeStr = word.substr(0, 2);
        let content = word.substr(3, word.length - 3)
        let type: AnnotationType = AnnotationType.Word;


        switch(typeStr) {
            case 'wd':
                type = AnnotationType.Word;
                break;
            case 'sp':
                type = AnnotationType.Spot;
                break;
            case 'hu':
                type = AnnotationType.Person;
                break;
            default:
                type = AnnotationType.Word;
                break;
        }

        let result = this.words.find(annotation => annotation.type == type &&  annotation.target == content);
        return result;
    }

    public static GetWeekEndClass(date: Date): string {
        switch(date.getDay()) {
            case 0:
                return 'sunday';
            case 6:
                return 'saturday';
            default:
                return '';
        }
    }

    public static homeSlideImages: string[] = [];

    public static GetDateStringSolo(date: Date, isYear: boolean, isMonth: boolean): string {
        return (isYear ? (date.getFullYear() + '年') : '') + (isMonth ? ((date.getMonth() + 1) + '月'): '') + date.getDate() + '日（' + this.GetWeek(date) + '）';
    }

    public static getDate(sd: StructDate | null | undefined): Date {
        if(sd == undefined || sd == null) return new Date();
        return new Date(sd.year, sd.month - 1, sd.day);
    }

    public static GetDateString(date1: Date, date2: Date): string {
        let today = new Date();
        let date1Text = this.GetDateStringSolo(date1, date1.getFullYear() != today.getFullYear(), true);

        if(date1 == date2)
            return date1Text
        else
            return date1Text + '-' + this.GetDateStringSolo(date2, date1.getFullYear() != date2.getFullYear(), date1.getFullYear() != date2.getFullYear() || date1.getMonth() != date2.getMonth());
    }

    public static get activities(): Activity[]{
        const today = new Date();
        return this.sortActivities( this._activities.filter(activity => !activity.isAirticle && this.getDateNumber(today) <= this.getDateNumber(Api.getDate(activity.endDate)) || activity.importance != Importance.Normal));
    }

    public static get normalActivities(): Activity[]{
        const today: Date = new Date();
        return this.sortActivities( this._activities.filter(activity => !activity.isAirticle && this.getDateNumber(today) <= this.getDateNumber(Api.getDate(activity.endDate)) && activity.importance == Importance.Normal));
    }

    public static get topicActivities(): Activity[]{
        const today = new Date();
        return this.sortDecendingActivities( this._activities.filter(activity => activity.isImportantAsTopic || activity.importance != Importance.Caution && this.getDateNumber(today) >= this.getDateNumber(Api.getDate(activity.endDate))));
    }

    public static getDateNumber(date: Date): number {
        return date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
    }

    public static GetImgeUrl(fileName: string): string {
        return 'https://takayautymawebstorage.blob.core.windows.net/images/' + fileName + '??sp=r&st=2025-01-22T08:14:16Z&se=2040-01-22T16:14:16Z&spr=https&sv=2022-11-02&sr=c&sig=5gJcC2xsN1khvmC0vcmwu56v7uP%2FK1EjFRS%2FyQT6sDg%3D';
    }

    public static GetNewsletterUrl(fileName: string): string {
        return 'https://takayautymawebstorage.blob.core.windows.net/newsletter/' + fileName + '?sp=r&st=2025-01-22T08:20:07Z&se=2040-01-22T16:20:07Z&spr=https&sv=2022-11-02&sr=c&sig=tGiwz4CDhCo%2FgSNOrNq%2FKgjX%2BoOeR%2Fi31JU9cT88zF8%3D';
    }

    static sortActivities(records: Activity[]): Activity[] {
        return records.sort((a, b) =>{
            let a1 = Api.getDate(a.date);
            let b1 = Api.getDate(b.date);

            if(a.importance == Importance.Caution && b.importance == Importance.Normal) return -1;
            if(a.importance == Importance.Normal && b.importance == Importance.Caution) return 1;
            if(a1 < b1) return -1;
            if(a1 > b1) return 1;
            return 0;
        });
    }

    static sortDecendingActivities(records: Activity[]): Activity[] {
        return records.sort((a, b) =>{
            let a1 = Api.getDate(a.date);
            let b1 = Api.getDate(b.date);

            if(a1 > b1) return -1;
            if(a1 < b1) return 1;
            return 0;
        });
    }

    public static GetFirstRecentNewsletter(): Newsletter {
        let today = new Date();
        return this.newsletters[0];
    }

    public static get newsletters() : Newsletter[] {
        return this.newsletterDatas.sort((a, b) => a.publishDate > b.publishDate ? -1 : 1);
    }

    static newsletterDatas: Newsletter[] = [
        {
            id: 1,
            title: '髙安分会報立教183年2月号',
            publishDate: new Date(2020,1,23),
            description: '',
            thumImage:'202002_0.jpg',
            images: ['202002_1.jpg', '202002_2.jpg', '202002_3.jpg', '202002_4.jpg', '202002_5.jpg', '202002_6.jpg', '202002_7.jpg', '202002_8.jpg', '202002_9.jpg', '202002_10.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 2,
            title: '髙安分会報立教183年3月号',
            publishDate: new Date(2020,2,23),
            description: '',
            thumImage:'202003_0.jpg',
            images: ['202003_1.jpg', '202003_2.jpg', '202003_3.jpg', '202003_4.jpg', '202003_5.jpg', '202003_6.jpg', '202003_7.jpg', '202003_8.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 3,
            title: '髙安分会報立教183年4月号',
            publishDate: new Date(2020,3,23),
            description: '',
            thumImage:'202004_0.jpg',
            images: ['202004_1.jpg', '202004_2.jpg', '202004_3.jpg', '202004_4.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 4,
            title: '髙安分会報立教183年5月号',
            publishDate: new Date(2020,4,23),
            description: '',
            thumImage:'202005_0.jpg',
            images: ['202005_1.jpg', '202005_2.jpg', '202005_3.jpg', '202005_4.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 5,
            title: '髙安分会報立教183年6月号',
            publishDate: new Date(2020,5,23),
            description: '',
            thumImage:'202006_0.jpg',
            images: ['202006_1.jpg', '202006_2.jpg', '202006_3.jpg', '202006_4.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 6,
            title: '髙安分会報立教183年7月号',
            publishDate: new Date(2020,6,23),
            description: '',
            thumImage:'202007_0.jpg',
            images: ['202007_1.jpg', '202007_2.jpg', '202007_3.jpg', '202007_4.jpg', '202007_5.jpg', '202007_6.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 7,
            title: '髙安分会報立教183年8月号',
            publishDate: new Date(2020,7,23),
            description: '',
            thumImage:'202008_0.jpg',
            images: ['202008_1.jpg', '202008_2.jpg', '202008_3.jpg', '202008_4.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 8,
            title: '髙安分会報立教183年9月号',
            publishDate: new Date(2020,8,23),
            description: '',
            thumImage:'202009_0.jpg',
            images: ['202009_1.jpg', '202009_2.jpg', '202009_3.jpg', '202009_4.jpg', '202009_5.jpg', '202009_6.jpg', '202009_7.jpg', '202009_8.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 8,
            title: '髙安分会報立教183年10月号',
            publishDate: new Date(2020,9,23),
            description: '',
            thumImage:'202010_00.jpg',
            images: ['202010_01.jpg', '202010_02.jpg', '202010_03.jpg', '202010_04.jpg', '202010_05.jpg', '202010_06.jpg', '202010_07.jpg', '202010_08.jpg', '202010_09.jpg', '202010_10.jpg', '202010_11.jpg', '202010_12.jpg', '202010_13.jpg', '202010_14.jpg', '202010_15.jpg', '202010_16.jpg', '202010_17.jpg', '202010_18.jpg', '202010_19.jpg', '202010_20.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 9,
            title: '髙安分会報立教183年11月号',
            publishDate: new Date(2020,10,23),
            description: '',
            thumImage:'202011_00.jpg',
            images: ['202011_01.jpg', '202011_02.jpg', '202011_03.jpg', '202011_04.jpg', '202011_05.jpg', '202011_06.jpg', '202011_07.jpg', '202011_08.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 10,
            title: '髙安分会報立教183年12月号',
            publishDate: new Date(2020,11,23),
            description: '',
            thumImage:'202012_00.jpg',
            images: ['202012_01.jpg', '202012_02.jpg', '202012_03.jpg', '202012_04.jpg', '202012_05.jpg', '202012_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 10,
            title: '髙安分会報立教184年1月号',
            publishDate: new Date(2021,0,23),
            description: '',
            thumImage:'202101_00.jpg',
            images: ['202101_01.jpg', '202101_02.jpg', '202101_03.jpg', '202101_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 11,
            title: '髙安分会報立教184年2月号',
            publishDate: new Date(2021,1,23),
            description: '',
            thumImage:'202102_00.jpg',
            images: ['202102_01.jpg', '202102_02.jpg', '202102_03.jpg', '202102_04.jpg', '202102_05.jpg', '202102_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 12,
            title: '髙安分会報立教184年3月号',
            publishDate: new Date(2021,2,23),
            description: '',
            thumImage:'202103_00.jpg',
            images: ['202103_01.jpg', '202103_02.jpg', '202103_03.jpg', '202103_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 13,
            title: '髙安分会報立教184年4月号',
            publishDate: new Date(2021,3,23),
            description: '',
            thumImage:'202104_00.jpg',
            images: ['202104_01.jpg', '202104_02.jpg', '202104_03.jpg', '202104_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 14,
            title: '髙安分会報立教184年5月号',
            publishDate: new Date(2021,4,23),
            description: '',
            thumImage:'202105_00.jpg',
            images: ['202105_01.jpg', '202105_02.jpg', '202105_03.jpg', '202105_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 15,
            title: '髙安分会報立教184年6月号',
            publishDate: new Date(2021,5,23),
            description: '',
            thumImage:'202106_00.jpg',
            images: ['202106_01.jpg', '202106_02.jpg', '202106_03.jpg', '202106_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 16,
            title: '髙安分会報立教184年7月号',
            publishDate: new Date(2021,6,23),
            description: '',
            thumImage:'202107_00.jpg',
            images: ['202107_01.jpg', '202107_02.jpg', '202107_03.jpg', '202107_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 17,
            title: '髙安分会報立教184年8月号',
            publishDate: new Date(2021,7,23),
            description: '',
            thumImage:'202108_00.jpg',
            images: ['202108_01.jpg', '202108_02.jpg', '202108_03.jpg', '202108_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 18,
            title: '髙安分会報立教184年9月号',
            publishDate: new Date(2021,8,23),
            description: '',
            thumImage:'202109_00.jpg',
            images: ['202109_01.jpg', '202109_02.jpg', '202109_03.jpg', '202109_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 19,
            title: '髙安分会報立教184年10月号',
            publishDate: new Date(2021,9,23),
            description: '',
            thumImage:'202110_00.webp',
            images: ['202110_01.webp', '202110_02.webp', '202110_03.webp', '202110_04.webp', '202110_05.webp', '202110_06.webp'],
            direction: Direction.RightToLeft,
        },
        {
            id: 20,
            title: '髙安分会報立教184年11月号',
            publishDate: new Date(2021,10,23),
            description: '',
            thumImage:'202111_00.jpg',
            images: ['202111_01.jpg', '202111_02.jpg', '202111_03.jpg', '202111_04.jpg', '202111_05.jpg', '202111_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 21,
            title: '髙安分会報立教184年12月号',
            publishDate: new Date(2021,11,23),
            description: '',
            thumImage:'202112_00.jpg',
            images: ['202112_01.jpg', '202112_02.jpg', '202112_03.jpg', '202112_04.jpg', '202112_05.jpg', '202112_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 22,
            title: '髙安分会報立教185年1月号',
            publishDate: new Date(2022,0,23),
            description: '',
            thumImage:'202201_00.jpg',
            images: ['202201_01.jpg', '202201_02.jpg', '202201_03.jpg', '202201_04.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 23,
            title: '髙安分会報立教185年2月号',
            publishDate: new Date(2022,1,23),
            description: '',
            thumImage:'202202_00.jpg',
            images: ['202202_01.jpg', '202202_02.jpg', '202202_03.jpg', '202202_04.jpg', '202202_05.jpg', '202202_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 24,
            title: '髙安分会報立教185年3月号',
            publishDate: new Date(2022,2,23),
            description: '',
            thumImage:'202203_00.jpg',
            images: ['202203_01.jpg', '202203_02.jpg', '202203_03.jpg', '202203_04.jpg', '202203_05.jpg', '202203_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 25,
            title: '髙安分会報立教185年4月号',
            publishDate: new Date(2022,3,23),
            description: '',
            thumImage:'202204_00.jpg',
            images: ['202204_01.jpg', '202204_02.jpg', '202204_03.jpg', '202204_04.jpg', '202204_05.jpg', '202204_06.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 26,
            title: '髙安分会報立教185年5月号',
            publishDate: new Date(2022,4,23),
            description: '',
            thumImage:'202205_00.jpg',
            images: ['202205_01.jpg', '202205_02.jpg', '202205_03.jpg', '202205_04.jpg', '202205_05.jpg', '202205_06.jpg', '202205_07.jpg', '202205_08.jpg'],
            direction: Direction.RightToLeft,
        },
        {
            id: 27,
            title: '髙安分会報立教185年11月号',
            publishDate: new Date(2022,10,23),
            description: '',
            thumImage:'202211_00.jpg',
            images: ['202211_01.jpg', '202211_02.jpg', '202211_03.jpg', '202211_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 28,
            title: '髙安分会報立教185年12月号',
            publishDate: new Date(2022,11,23),
            description: '',
            thumImage:'202212_00.jpg',
            images: ['202212_01.jpg', '202212_02.jpg', '202212_03.jpg', '202212_04.jpg', '202212_05.jpg', '202212_06.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 29,
            title: '髙安分会報立教186年1月号',
            publishDate: new Date(2023,0,23),
            description: '',
            thumImage:'202301_00.jpg',
            images: ['202301_01.jpg', '202301_02.jpg', '202301_03.jpg', '202301_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 30,
            title: '髙安分会報立教186年2月号',
            publishDate: new Date(2023,1,23),
            description: '',
            thumImage:'202302_00.jpg',
            images: ['202302_01.jpg', '202302_02.jpg', '202302_03.jpg', '202302_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 31,
            title: '髙安分会報立教186年3月号',
            publishDate: new Date(2023,2,23),
            description: '',
            thumImage:'202303_00.jpg',
            images: ['202303_01.jpg', '202303_02.jpg', '202303_03.jpg', '202303_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 32,
            title: '髙安分会報立教186年4月号',
            publishDate: new Date(2023,3,23),
            description: '',
            thumImage:'202304_00.jpg',
            images: ['202304_01.jpg', '202304_02.jpg', '202304_03.jpg', '202304_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 33,
            title: '髙安分会報立教186年5月号',
            publishDate: new Date(2023,4,23),
            description: '',
            thumImage:'202305_00.jpeg',
            images: ['202305_01.jpeg', '202305_02.jpeg', '202305_03.jpeg', '202305_04.jpeg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 34,
            title: '髙安分会報立教186年6月号',
            publishDate: new Date(2023,5,23),
            description: '',
            thumImage:'2023062300.jpeg',
            images: ['2023062301.jpeg', '2023062302.jpeg', '2023062303.jpeg', '2023062304.jpeg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 35,
            title: '髙安分会報立教186年7月号',
            publishDate: new Date(2023,6,23),
            description: '',
            thumImage:'202307_00.jpg',
            images: ['202307_01.jpg', '202307_02.jpg', '202307_03.jpg', '202307_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 37,
            title: '髙安分会報立教186年8月号',
            publishDate: new Date(2023,7,23),
            description: '',
            thumImage:'202308_00.jpg',
            images: ['202308_01.jpg', '202308_02.jpg', '202308_03.jpg', '202308_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 38,
            title: '髙安分会報立教186年9月号',
            publishDate: new Date(2023,8,23),
            description: '',
            thumImage:'202309_00.jpg',
            images: ['202309_01.jpg', '202309_02.jpg', '202309_03.jpg', '202309_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 39,
            title: '髙安分会報立教186年10月号',
            publishDate: new Date(2023,9,23),
            description: '',
            thumImage:'202310_00.jpg',
            images: ['202310_01.jpg', '202310_02.jpg', '202310_03.jpg', '202310_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 40,
            title: '髙安分会報立教186年11月号',
            publishDate: new Date(2023,10,23),
            description: '',
            thumImage:'202311_00.jpg',
            images: ['202311_01.jpg', '202311_02.jpg', '202311_03.jpg', '202311_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 41,
            title: '髙安分会報立教186年12月号',
            publishDate: new Date(2023,11,23),
            description: '',
            thumImage:'202312_00.jpg',
            images: ['202312_01.jpg', '202312_02.jpg', '202312_03.jpg', '202312_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 42,
            title: '髙安分会報立教187年1月号',
            publishDate: new Date(2024,0,23),
            description: '',
            thumImage:'202401_00.jpg',
            images: ['202401_01.jpg', '202401_02.jpg', '202401_03.jpg', '202401_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 43,
            title: '髙安分会報立教187年2月号',
            publishDate: new Date(2024,1,23),
            description: '',
            thumImage:'202402_00.jpg',
            images: ['202402_01.jpg', '202402_02.jpg', '202402_03.jpg', '202402_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 44,
            title: '髙安分会報立教187年3月号',
            publishDate: new Date(2024,2,23),
            description: '',
            thumImage:'202403_00.jpg',
            images: ['202403_01.jpg', '202403_02.jpg', '202403_03.jpg', '202403_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 45,
            title: '髙安分会報立教187年4月号',
            publishDate: new Date(2024,3,23),
            description: '',
            thumImage:'202404_00.jpg',
            images: ['202404_01.jpg', '202404_02.jpg', '202404_03.jpg', '202404_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 46,
            title: '髙安分会報立教187年5月号',
            publishDate: new Date(2024,4,23),
            description: '',
            thumImage:'202405_00.jpg',
            images: ['202405_01.jpg', '202405_02.jpg', '202405_03.jpg', '202405_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 47,
            title: '髙安分会報立教187年6月号',
            publishDate: new Date(2024,5,23),
            description: '',
            thumImage:'202406_00.jpg',
            images: ['202406_01.jpg', '202406_02.jpg', '202406_03.jpg', '202406_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 48,
            title: '髙安分会報立教187年7月号',
            publishDate: new Date(2024,6,23),
            description: '',
            thumImage:'202407_00.jpg',
            images: ['202407_01.jpg', '202407_02.jpg', '202407_03.jpg', '202407_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 49,
            title: '髙安分会報立教187年8月号',
            publishDate: new Date(2024,7,23),
            description: '',
            thumImage:'202408_00.jpg',
            images: ['202408_01.jpg', '202408_02.jpg', '202408_03.jpg', '202408_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 49,
            title: '髙安分会報立教187年9月号',
            publishDate: new Date(2024,8,23),
            description: '',
            thumImage:'20240823_00.jpg',
            images: ['20240823_01.jpg', '20240823_02.jpg', '20240823_03.jpg', '20240823_04.jpg', '20240823_05.jpg', '20240823_06.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 50,
            title: '髙安分会報立教187年10月号',
            publishDate: new Date(2024,9,23),
            description: '',
            thumImage:'2410_00.jpg',
            images: ['2410_01.jpg', '2410_02.jpg', '2410_03.jpg', '2410_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 51,
            title: '髙安分会報立教187年11月号',
            publishDate: new Date(2024,10,23),
            description: '',
            thumImage:'2411_00.jpg',
            images: ['2411_01.jpg', '2411_02.jpg', '2411_03.jpg', '2411_04.jpg'],
            direction: Direction.LeftToRight,
        }, 
        {
            id: 52,
            title: '髙安分会報立教187年12月号',
            publishDate: new Date(2024,11,23),
            description: '',
            thumImage:'2412_00.jpeg',
            images: ['2412_01.jpeg', '2412_02.jpeg', '2412_03.jpeg', '2412_04.jpeg'],
            direction: Direction.LeftToRight,
        }, 
        {
            id: 53,
            title: '髙安分会報立教188年1月号',
            publishDate: new Date(2025,0,23),
            description: '',
            thumImage:'2501_00.jpeg',
            images: ['2501_01.jpeg', '2501_02.jpeg', '2501_03.jpeg', '2501_04.jpeg'],
            direction: Direction.LeftToRight,
        }, 
        {
            id: 53,
            title: '髙安分会報立教188年2月号',
            publishDate: new Date(2025,1,23),
            description: '',
            thumImage:'202502_00.jpg',
            images: ['202502_01.jpg', '202502_02.jpg', '202502_03.jpg', '202502_04.jpg'],
            direction: Direction.LeftToRight,
        },
        {
            id: 54,
            title: '髙安分会報立教188年3月号',
            publishDate: new Date(2025,2,23),
            description: '',
            thumImage:'202503_00.webp',
            images: ['202503_01.webp', '202503_02.webp', '202503_03.webp', '202503_04.webp'],
            direction: Direction.LeftToRight,
        }, 
    ];

    static _activities: Activity[] = [];
    static inheritChurches: Church[] = [];
    static churches: Church[] = [];
    public static Route: PageRoute = PageRoute.Home;
    public static isPageWatchDogActive: boolean = false;

    public static TrainingCount: number = 0;

    static SetRoute(route: PageRoute) {
        this.Route = route;
        this.isPageWatchDogActive = true;
    }

    static Post<T>(command: string, data: any, func: (data: T) => void) {
        axios.post(this.info.url + command + '?code=' + this.info.key,
            data,  {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }}).then((res: any) => {
                func(res.data);
            });
    }

    static Get<T>(url :string, func: (data: T) => void) {
        axios.get(url,  {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/x-www-form-urlencoded',
            }}).then((res: any) => {
                func(res);
            });
    }


    static cache: Cache;

    public static Init(func: () => void) {
        Cache.readCash((time, json) =>{
            if(time && json) {
                this.SetInitData(json, func);
                this.Post<GetDataReponse>('GetData', { time: time }, data => {
                    if(data.isSucceed && !data.json) 
                        this.SetInitData(json, func);
                    else if(data.isSucceed) {
                        this.SetInitData(data.json, func);
                        Cache.saveCash(data.time, data.json);
                    }
                });
            } else {
                this.Post<GetDataReponse>('GetData', { time: '' }, data => {
                    console.log(data.json);
                    this.SetInitData(data.json, func);
                    Cache.saveCash(data.time, data.json);
                });
            }
        });
    }

    static SetInitData(json: string, func: () => void) {
        let initData = JSON.parse(json) as DeployData;
        this._activities = initData.activities;
        this.words = initData.annotations;
        this.homeSlideImages = initData.option?.slides.map(slide => slide.image) ?? [];
        this.churches = initData.churches ?? [];
        this.fixChurch(this.churches);

        func();
    }

    static fixChurch(churches: Church[]) {
        churches.forEach(church => church.parent = null);
        churches.forEach(church => {
            if(church.childrenIds == null || church.childrenIds == undefined) {
                church.childrenIds = [];
            }
            
            church.children = church.childrenIds.map(id => {
                let child = churches.find(c => c.id == id);
                if(child == undefined || child == null) return null;
                
                child.parent = church;
                return child;
            }).filter(x => x != null) as Church[];
        });

        this.inheritChurches = churches.filter(church => church.parent == undefined || church.parent == null);
    }

    public static GetPx(mm: number) : number {
        let inch = document.getElementById('inch')?.clientWidth;
        if(inch == undefined)
            return mm;
        else
            return mm * inch / 25.4;
    }
    
    public static AnimateShowActivityDialog() {
        let element = document.getElementById('activityDialog');
        element?.classList.remove('dialog-active');

        setTimeout(() => {
            element?.classList.add('dialog-active');    
        }, 100);
    }
    
    public static AnimateCloseActivityDialog() {
        let element = document.getElementById('activityDialog');
        element?.classList.remove('dialog-active');
    }

    public static AnimateShowNewsletterDialog() {
        let element = document.getElementById('newsletterDialog');
        element?.classList.remove('dialog-active');

        setTimeout(() => {
            element?.classList.add('dialog-active');
        }, 100);
    }
    
    public static AnimateCloseNewsletterDialog() {
        let element = document.getElementById('newsletterDialog');
        element?.classList.remove('dialog-active');
    }
}